
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/rent/homes-for-rent",
      function () {
        return require("private-next-pages/rent/homes-for-rent.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/rent/homes-for-rent"])
      });
    }
  